.info {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 50px 0;
}
.info-title {
  color: bold;
  margin: 1rem;
}
.info-details {
  color: rgb(206, 32, 32);
}

.card {
  border: 2px solid red;
  padding: 20px;
  margin: 1rem;
}
