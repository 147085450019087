.footer {
  background-color: #000000; /* black background */
  color: #ffffff; /* white text */
  padding: 40px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
}

.footer-container div:first-of-type,
.footer-container div:nth-of-type(2) {
  border-right: 3px solid white;
  padding-right: 4rem; /* optional: add some space to the right of the line */
}

.footer-container div {
  width: 33.33%;
}

.info {
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-container div:first-of-type,
  .footer-container div:nth-of-type(2) {
    border-right: none;
    padding-right: 0;
  }
}
